import React, { useEffect, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import BreadCrumbs from '../components/BreadCrumbs'
import ServiceNav from '../components/ServiceNav'
import FormBuilder from '../components/builders/form-builder'
import { t } from 'ttag'
import Typed from 'react-typed';
import Picture from '../components/shared/picture'

interface ICustomProperties {
	codename: string
	mediaPath: string
	rs: {
		formTitle: string
		formSubtitle: string
		benefitSupport: string
		benefitServis: string
		benefitDevelopers: string
		benefitERP: string
		navigationTitle: string
	}
}

const LandingPage = ({ data, pageContext }: any) => {
	const page = data.orchard.landingPage[0];

	const customProperties: Partial<ICustomProperties> = {}

	switch (page.landingPageType) {
		case 'landing-page-helper--enterprise':
			customProperties.codename = 'enterprise'
			customProperties.mediaPath = process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page'
			customProperties.rs = {
				formTitle: t`PuxDesign.EnterpriseLandingPage.Form.Title`,
				formSubtitle: t`PuxDesign.EnterpriseLandingPage.Form.Subtitle`,
				benefitSupport: t`PuxDesign.EnterpriseLandingPage.Benefit.Support`,
				benefitServis: t`PuxDesign.EnterpriseLandingPage.Benefit.Servis`,
				benefitDevelopers: t`PuxDesign.EnterpriseLandingPage.Benefit.Developers`,
				benefitERP: t`PuxDesign.EnterpriseLandingPage.Benefit.ERP`,
				navigationTitle: t`PuxDesign.EnterpriseLandingPage.Navigation.Title`
			}
			break
		case 'landing-page-helper--kentico':
			customProperties.codename = 'kentico'
			customProperties.mediaPath = process.env.GATSBY_ORCHARD_API_URL + '/media/kentico%20landing%20page'
			customProperties.rs = {
				formTitle: t`PuxDesign.KenticoLandingPage.Form.Title`,
				formSubtitle: t`PuxDesign.KenticoLandingPage.Form.Subtitle`,
				benefitSupport: t`PuxDesign.KenticoLandingPage.Benefit.Support`,
				benefitServis: t`PuxDesign.KenticoLandingPage.Benefit.Servis`,
				benefitDevelopers: t`PuxDesign.KenticoLandingPage.Benefit.Developers`,
				benefitERP: t`PuxDesign.KenticoLandingPage.Benefit.ERP`,
				navigationTitle: t`PuxDesign.KenticoLandingPage.Navigation.Title`
			}
			break
	}

	if (!customProperties.codename && !customProperties.mediaPath) {
		return null
	}

	return (
		<Layout localizedPath={page.localization?.localizations}>
			<PuxMetaTags {...pageContext.metaTagsData} />

			<div className={'landing-page-helper ' + page.landingPageType ?? ""}>
				<div className="landingPage-topImage">
					<div className="pux-container">
						<div className='landingPage-topImage-inner'>
							<div className='landingPage-topImage-content'>
								<BreadCrumbs
									items={pageContext.breadcrumbs}
									center={false}
								/>
								<Typed
									strings={[page.landingPageAnimatedTitle?.html ?? ""]}
									typeSpeed={40}
									showCursor={false}
								/>
								<div dangerouslySetInnerHTML={{ __html: page.landingPagePerex?.html ?? "" }}></div>
							</div>
							<div className="landingPage-topImage-image">
								<Picture
									puxMediaSelectorImage={page.landingPageImage}
									lazy={false}
									alt={""}
								/>
								{/* <img loading='eager' src={`${process.env.GATSBY_ORCHARD_API_URL}/media/enterprise%20landing%20page/vousatej-typek-bez-pozadi.png`} alt='' /> */}
							</div>
						</div>
					</div>
				</div>

				{/* <div class='webtypes-wrapper'>
					<div class='pux-container'>
						<div class='webtypes-inner'>
							<div class='webtypes-side'>
								<div class='webtypes-side-title'>Ať už potřebujete postavit <span class='landingPage-highlight'>jakkoliv velké řešení</span></div>
								<div class='webtypes-side-cta'>
									<div class='content-divider'>
										<a class='landing-page-button'>Poznejte naše kvality</a>
									</div>
									<div class='content-divider for-respo'>
										<a class='landing-page-button for-respo'>Poptat kentiko</a>
									</div>
								</div>
							</div>
							<div class='webtypes-container'>
								<div class='webtypes-item'>
									<div class='webtypes-item-image'>
										<img loading='lazy' src="https://localhost:44365/media/kentico%20landing%20page/agrotec-image.png" alt='' />
									</div>
									<div class='webtypes-item-content'>
										<div class='webtypes-item-name'>
											Prezentační web
										</div>
										<div class='webtypes-item-perex'>
											Slušivá firemní vizitka, která promění obyčejné návštěvníky ve skutečné klienty.
										</div>
									</div>
								</div>

								<div class='webtypes-item'>
									<div class='webtypes-item-image'>
										<img loading='lazy' src="https://localhost:44365/media/kentico%20landing%20page/carollinum-image.png" alt='' />
									</div>
									<div class='webtypes-item-content'>
										<div class='webtypes-item-name'>
											E-shop
										</div>
										<div class='webtypes-item-perex'>
											Řešení, které se mění v průběhu let s vámi. Od základního prodejního kanálu až po e-shopy s nabušenými funkcionalitami na míru.
										</div>
									</div>
								</div>

								<div class='webtypes-item'>
									<div class='webtypes-item-image'>
										<img loading='lazy' src="https://localhost:44365/media/kentico%20landing%20page/konica-image.png" alt='' />
									</div>
									<div class='webtypes-item-content'>
										<div class='webtypes-item-name'>
											Zákaznický portál
										</div>
										<div class='webtypes-item-perex'>
											Takový váš malý soukromý Google, kde najdete vše co potřebujete. Přehledná platforma pro vás samotné i pro vaše obchodní partnery.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}

				{/* <div class='awards-wrapper'>
					<div class='pux-container'>
						<div class='awards-inner'>
							<div class='awards-image'>
								<img loading='lazy' src='https://localhost:44365/media/kentico%20landing%20page/awards-photo.png' alt='' />
							</div>
							<div class='awards-container-wrapper'>
								<div class='awards-container'>
									<a class='awards-item'>
										<div class='awards-logo'>
											<img loading='lazy' src='https://localhost:44365/media/kentico%20landing%20page/oloucky-kraj-logo.svg' alt='' />
										</div>
										<div class='awards-label'>
											<img loading='lazy' src='https://localhost:44365/media/kentico%20landing%20page/sotm-2022-badge-february.svg' alt='' />
										</div>
									</a>

									<a class='awards-item'>
										<div class='awards-logo'>
											<img loading='lazy' src='https://localhost:44365/media/kentico%20landing%20page/logo-zkl.svg' alt='' />
										</div>
										<div class='awards-label'>
											<img loading='lazy' src='https://localhost:44365/media/kentico%20landing%20page/sotm-2022-badge-january.svg' alt='' />
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div> */}

				{/* <div className="landingPage-bigProjects">
					<div className="pux-container">
						<h2 className="landingPage-bigProjects-title">
							<span className='landingPage-highlight'>Velké</span> projekty - to my umíme.
						</h2>
						<div className="landingPage-bigProjects-perex">
							Ať už jste z jakéhokoliv segmentu
						</div>
						<div className="landingPage-bigProjects-grid">
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">Bankovnictví</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/KB.svg'} alt="logo KB" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Komerční banka v nás má mimo jiné partnera pro plnění a úpravu obsahu. Celkem už více než 3 000 hodin.
									<br />
									<br />
									Náš 50členný tým specialistů reaguje na požadavky klienta k jednotlivým projektům pružně a dodává každou část řešení vždy přesně na čas.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">IT</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/KM.svg'} alt="logo KM" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Spravujeme přes 20 jazykových mutací webu Konica Minolta propojených jednou partnerskou zónou.
									<br />
									<br />
									V rozmezí let 2015 až 2022 jsme dodali řešení v hodnotě přes 100 milionů Kč. Pro klienta jsme vyčlenili samostatný tým programátorů, kteří dlouhodobě pracují pouze na tomto projektu.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">E-commerce</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/Comfor.svg'} alt="logo Comfor" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Pro Comfor jsme navrhli systém, který s přehledem zpracovává stovky tisíc produktů.
									<br />
									<br />
									Sám klient zároveň může obsah na e-shopu sám editovat. I kdyby tím týdně trávil jen dvě hodiny času, ročně firma ušetří 100 000 korun za delegaci práce druhé straně.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">Automotive</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/Pneuboss.svg'} alt="logo Pneuboss" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Správně nastavený e-shop TASY jsme pomohli za 6 let dostat nuly na roční obrat 50 mil. korun. Dnes firma dosahuje miliardového obratu.
									<br />
									<br />
									Vhodným informačním systémem jsme při redesignu zrychlili zpracování objednávek o 80 %.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">fintech</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/Axiory.svg'} alt="logo Axiory" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Měnové páry pro tradera Axiory aktualizujeme každých 5 sekund.
									<br />
									<br />
									Japonština či thajština s odlišnou strukturou webu patří mezi 7 jazykových mutací, kterým na webu Axiory plníme obsah.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">Řízení projektů</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/EasySoftware.svg'} alt="logo EasySoftware" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Postarali jsme se o bezpečné přenesení více než 7 000 stránek na tři nové weby Easy Software. Nové řešení jsme navíc čtyřnásobně zrychlili – web se kompletně načte už za jednu sekundu.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">Finance</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/ZFP.svg'} alt="logo ZFP" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									ZFP Group jsme zprostředkovali už přes 2 000 000 000 investic ošetřených maximálním zabezpečením.
								</div>
							</div>
							<div className="landingPage-bigProjects-item">
								<h3 className="landingPage-bigProjects-itemTitle">Zemědělství</h3>
								<div className="landingPage-bigProjects-itemImage">
									<img src={process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page/Bednar.svg'} alt="logo Bednar" />
								</div>
								<div className="landingPage-bigProjects-itemContent">
									Konfigurátor produktů Bednar navrhuje zákazníkům klienta přes 1 000 000 variant výroby zemědělských strojů včetně upozornění na kolizi mezi nevhodnými součástkami.
								</div>
							</div>
						</div>

						<div className='landingPage-bigProjects-button'>
							<a className='landing-page-button' href="#form">Mám zájem</a>
						</div>
					</div>
				</div> */}

				<WidgetBuilder widgetBuilderData={pageContext.widgets} />

				<section className="landingPage-form" id='form'>
					<div className="pux-container">
						<div className="center-content" dangerouslySetInnerHTML={{ __html: customProperties.rs?.formTitle ?? "" }}></div>
						<div className='landingPage-form-layout'>
							<div className='landingPage-form-layout-left'>
								<FormBuilder formId="landingPage" recaptchaUrl={pageContext.recaptchaUrl} />
							</div>
							<div className='landingPage-form-layout-right'>

								<div dangerouslySetInnerHTML={{ __html: customProperties.rs?.formSubtitle ?? "" }}></div>

								<div className='landing-person-item'>
									<div className='landing-person-item-image'>
										<img loading='lazy' src={`${customProperties.mediaPath}/jiri-juhanak-photo.png`} alt='' />
									</div>
									<div className='landing-person-item-content'>
										<div className='landing-person-item-name'>
											Jiří Juhaňák
										</div>
										<div className='landing-person-item-phone'>
											<a href='tel:+420605175519'>+420 605 175 519</a>
										</div>
										<div className='landing-person-item-email'>
											<a href='mailto:jiri.juhanak@puxdesign.cz'>jiri.juhanak@puxdesign.cz</a>
										</div>
									</div>
								</div>

								<div className='landing-benefit-container'>
									<div className='landing-benefit-item'>
										<div className='landing-benefit-item-icon'>
											<img loading='lazy' src={`${customProperties.mediaPath}/support-icon.svg`} alt='' />
										</div>
										<div className='landing-benefit-item-name'>
											{customProperties.rs?.benefitSupport ?? ""}
										</div>
									</div>

									<div className='landing-benefit-item'>
										<div className='landing-benefit-item-icon'>
											<img loading='lazy' src={`${customProperties.mediaPath}/servis-icon.svg`} alt='' />
										</div>
										<div className='landing-benefit-item-name'>
											{customProperties.rs?.benefitServis ?? ""}
										</div>
									</div>

									<div className='landing-benefit-item'>
										<div className='landing-benefit-item-icon'>
											<img loading='lazy' src={`${customProperties.mediaPath}/kentiko-developers-icon.svg`} alt='' />
										</div>
										<div className='landing-benefit-item-name'>
											{customProperties.rs?.benefitDevelopers ?? ""}
										</div>
									</div>

									<div className='landing-benefit-item'>
										<div className='landing-benefit-item-icon'>
											<img loading='lazy' src={`${customProperties.mediaPath}/erp-icon.svg`} alt='' />
										</div>
										<div className='landing-benefit-item-name'>
											{customProperties.rs?.benefitERP ?? ""}
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>

				<section className="landingPage-navigation">
					<div className='pux-container'>
						<h2 className="center-content">{customProperties.rs?.navigationTitle ?? ""}</h2>
					</div>
					<ServiceNav {...page.crossDivisionNavigation}></ServiceNav>
				</section>
				<section className='landingPage-socials'>
					<img className='landingPage-socials-background' loading='lazy' src={`${customProperties.mediaPath}/PuxSocialsBanner.png`} alt='' />
					<div className='landingPage-socials-content'>
						<div className='pux-container'>
							<h2 className='landingPage-socials-title'>{t`PuxDesign.LandingPage.Socials.Title`}</h2>
							<div className='landingPage-socials-perex'>{t`PuxDesign.LandingPage.Socials.Perex`}</div>
							<div className='landingPage-socials-buttons'>
								<a href={t`PuxDesign.LandingPage.Socials.Instagram`} target='_blank' className='landingPage-socials-button-instagram'></a>
								<a href={t`PuxDesign.LandingPage.Socials.Facebook`} target='_blank' className='landingPage-socials-button-facebook'></a>
								<a href={t`PuxDesign.LandingPage.Socials.Linkedin`} target='_blank' className='landingPage-socials-button-linkedin'></a>
							</div>
						</div>
					</div>
				</section>
			</div>

		</Layout>
	)
}

export default LandingPage

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      landingPage(first: 1, where: { path: $pagePath }) {
        landingPagePerex {
          html
        }
        landingPageAnimatedTitle {
          html
        }
		landingPageImage {
		  resizePaths(width: 1920, mode: "Min", quality: 75)
          resizePathsMobile(width: 768, mode: "Min", quality: 75)
          resizePathsTablet(width: 1200, mode: "Min", quality: 75)
		}
		landingPageType
        displayText
        localization {
          localizations {
            ... on Orchard_LandingPage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        crossDivisionNavigation {
          contentItems {
            ... on Orchard_PuxDesignServiceNavigationItem {
              puxDesignServiceNavigationItemCustomDescription
              puxDesignServiceNavigationItemCustomTitle
              puxDesignServiceNavigationItemService {
                contentItems {
                  ... on Orchard_PuxDesignServicePage {
                    displayText
                    puxDesignServicePageDescription
                    path
                    puxDesignIconSelector {
                      puxDesignIconSelectorIcon
                    }
                    puxDesignThemeSelector {
                      puxDesignThemeSelectorTheme
                    }
                  }
                }
              }
            }
            
          }
        }
      }
    }
  }
`
